import React from "react";
import { Route } from "react-router-dom";
import Slider from "./components/Landing/Slider";
import Main from "./components/Main/Main";
import Contact from "./components/Contact/Contact";
import Services from "./components/Services/Services";
import Blog from "./components/Blog/Blog";
import Projects from "./components/Projects/Projects";
import MoneyDrop from "./components/MoneyDrop";


function App() {
  return (
   
    
    <div className="App">
    <Route path="/" exact component={Slider} />
   <Route path="/mondrop" exact component={MoneyDrop}/> 
     <Route path="/home" exact component={Main} />
     <Route path="/contact" exact component={Contact} />
     <Route path="/blog" exact component={Blog} />
     <Route path="/projects" exact component={Projects} />
     <Route path="/services" exact component={Services} />
 </div>


    
   
  
  );
}

export default App;
