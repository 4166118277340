import React, { Component } from 'react'
import { Player } from 'video-react';

export default class MoneyDrop extends Component {


    render() {
        
        return (
            <div>
    <Player style={[]}>
      <source src="https://www.derekwebdev.com/video/money.mp4" />
    </Player>
            </div>
        )
    }
}