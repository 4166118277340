import React from 'react';
import Slide from 'react-reveal/Slide';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Landing.scss';
import ReactGA from 'react-ga';
const trackingId = "UA-139597870-1"; 
ReactGA.initialize(trackingId);

class Slider extends React.Component {
  state ={
    redirect: false
  }
  constructor(props) {
    super(props);
    this.state = { show: true };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState({ show: !this.state.show });
    this.setState({ redirect: false });
  }
  UNSAFE_componentWillUpdate(){
    setTimeout(() => {
        this.props.history.push('/home');
    }, 2500)
}
  render() {
    return (
      <div>
   <div className="container-fluid">
     <div className="row">
      <Slide left when={this.state.show}>
          <div className="col-6">
        <img src="https://derekwebdev.com/images/curtainleft.png" alt="curtainright" className="float-right" />
        </div>
        </Slide>
      <Slide right when={this.state.show}>
          <div className="col-6" >
        <img src="https://derekwebdev.com/images/curtainright.png" alt="curtainleft" className="float-left"/>
        </div>
        </Slide>
        </div>
        </div>
     <div className="buttonlogo col-12">
   <img src="https://derekwebdev.com/images/derekwebdevlogon.png" alt="logo" className="rounded mx-auto d-block front-logo mb-3" />
        <button className="btn btn-outline-warning btn-lg portbtn " type="button"
          onClick={this.handleClick} > { this.state.show } Enter Portfolio
        </button>
        <h3 className="text-center title">DerekWebDev</h3>
        </div>
        </div>
    );
  }
}
export default Slider;
