import React, { Component } from 'react'
import '../Main/Main.scss';
import Slide from 'react-reveal/Slide';
import Navbar from '../Navbar/Navbar';
import SideDrawer from '../SideDrawer/SideDrawer';
import Backdrop from '../Backdrop/Backdrop';

export default class Main extends Component {
  state={
  sideDrawerOpen: false
};
drawerToggleClickHandler = () => {
this.setState((prevState) => {
  return {sideDrawerOpen: !prevState.sideDrawerOpen};
});
};
backdropClickHandler = () => {
  this.setState({sideDrawerOpen: false})
};
CompnentDidUpdate(){
  setTimeout(() => {
      this.props.history.push('/home');
  }, 0)
}
    render() {
      let sideDrawer;
      let backdrop;
      if (this.state.sideDrawerOpen){
        sideDrawer = <SideDrawer />
        backdrop = <Backdrop click={this.backdropClickHandler} />
      }
        return (
<div className="page-bg">
<div id="wrap">
<Navbar drawerClickHandler={this.drawerToggleClickHandler}/>
{sideDrawer}
{backdrop}
<main style={{marginTop: '64px'}}>
<div className="animation-wrapper">
  <div className="particle particle-1"></div>
  <div className="particle particle-2"></div>
  <div className="particle particle-3"></div>
  <div className="particle particle-4"></div>
              <div className="container-fluid mt-5 mb-2">
                <Slide bottom>
              <img src="https://derekwebdev.com/images/derekwebdevlogon.png" alt="logo" className="rounded mx-auto d-block pt-2 logo"/>
              </Slide>
              <div className="text-center text-white mt-4 mb-3">
        <Slide bottom>
          <h1>This is DerekWebDev</h1>
          <h1>Fullstack Software Engineering</h1>
         </Slide>
      </div>
          </div>
              <div>
        <Slide bottom>
<div className="row social-icons">
<a href="https://github.com/derekwebdevcom" rel="noopener noreferrer"  target="_blank"><img src="https://derekwebdev.com/images/github.png" alt="github" height="60vh" width="70vh" className="d-block pr-3"/></a>
<a href="https://codepen.io/derek-andrews/" rel="noopener noreferrer"  target="_blank"><img src="https://derekwebdev.com/images/codepen.png" alt="logo" height="60vh" width="63vh" className="d-block"/></a>
<a href="https://linkedin.com/in/derek-andrews-5280" rel="noopener noreferrer" target="_blank"><img src="https://derekwebdev.com/images/linkedin.png" alt="logo" height="60vh" width="65vh" className="d-block pl-3"/></a>
</div>
</Slide>
      </div>
</div>
</main>
</div>
</div>
        )
    }
    
}
