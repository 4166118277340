import React, { Component } from 'react'
import './Contact.scss';
import Navbar from '../Navbar/Navbar';
import SideDrawer from '../SideDrawer/SideDrawer';
import Slide from 'react-reveal/Slide';
import MyForm from './MyForm';
import Backdrop from '../Backdrop/Backdrop';

export default class Contact extends Component {
  state={
    sideDrawerOpen: false
  };
  drawerToggleClickHandler = () => {
  this.setState((prevState) => {
    return {sideDrawerOpen: !prevState.sideDrawerOpen};
  });
  }; 
  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false}) 
  };
  CompnentDidUpdate(){
    setTimeout(() => { 
        this.props.history.push('/contact');
    }, 0)
  }
      render() {
        let sideDrawer;
        let backdrop;
        if (this.state.sideDrawerOpen){
          sideDrawer = <SideDrawer />
          backdrop = <Backdrop click={this.backdropClickHandler} />
        }
          return (       
  <div className="page-bg">
  <Navbar drawerClickHandler={this.drawerToggleClickHandler}/>
  {sideDrawer}
  {backdrop}
<div className="animation-wrapper">
  <div className="particle particle-1"></div>
  <div className="particle particle-2"></div>
  <div className="particle particle-3"></div>
  <div className="particle particle-4"></div>
<div style={{marginTop: '45px', marginBottom: '0px'}}>
        <Slide top>
        <h2 className="text-center mt-2 text-white">Contact</h2>
        </Slide>
      </div>
  <div>
  <Slide bottom>
<div className="container-fluid ">
  <div className="row">
<MyForm/>
</div>
</div>
</Slide>
</div>   
      <div>
        <Slide bottom>
<div className="row social-icons2"> 
<a href="https://github.com/derekwebdevcom" rel="noopener noreferrer"  target="_blank"><img src="https://derekwebdev.com/images/github.png" alt="github" height="60vh" width="70vh" className="d-block pr-3"/></a>
<a href="https://codepen.io/derek-andrews/" rel="noopener noreferrer"  target="_blank"><img src="https://derekwebdev.com/images/codepen.png" alt="logo" height="60vh" width="63vh" className="d-block"/></a>
<a href="https://linkedin.com/in/derek-andrews-5280" rel="noopener noreferrer" target="_blank"><img src="https://derekwebdev.com/images/linkedin.png" alt="logo" height="60vh" width="65vh" className="d-block pl-3"/></a>
</div>
</Slide>
   </div>        
 </div>
</div>
        )
    }
}
