import React, { Component } from 'react'
import '../Main/Main.scss';
import Navbar from '../Navbar/Navbar';
import Slide from 'react-reveal/Slide';
import { AutoRotatingCarousel } from 'material-auto-rotating-carousel';
import SideDrawer from '../SideDrawer/SideDrawer';
import Backdrop from '../Backdrop/Backdrop';

export default class Projects extends Component {

  state={
    sideDrawerOpen: false,

  };

    constructor(props) {
      super(props)
      this.state = { open: false }
      this.state = { show: false };
      this.handleClick = this.handleClick.bind(this);

    }
      handleClick() {
      this.setState({ show: !this.state.show });
      this.setState({ open: !this.state.open});
      this.setState({render: !this.state.render});

    }
     drawerToggleClickHandler = () => {
  this.setState((prevState) => {
    return {sideDrawerOpen: !prevState.sideDrawerOpen};
  });
  };
  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false})
  };
     render() {
      let sideDrawer;
      let backdrop;
      if (this.state.sideDrawerOpen){
        sideDrawer = <SideDrawer />
        backdrop = <Backdrop click={this.backdropClickHandler} />
      }

      const Slider = require('./Slide').default;

     return (
           <div className="page-bg">
<Navbar drawerClickHandler={this.drawerToggleClickHandler}/>
{sideDrawer}
{backdrop}
<div style={{marginTop: '7%', marginBottom: '5%'}}>
<Slide top>
        <h2 className="text-center mt-2 text-white">Projects</h2>
        </Slide>
      </div>
  <div className="animation-wrapper">
  <div className="particle particle-1"></div>
  <div className="particle particle-2"></div>
  <div className="particle particle-3"></div>
  <div className="particle particle-4"></div>
  </div>

  <Slide bottom>
  <div style={{ position: 'relative', width: '100%', height: 500 }}>
  <button  className="btn btn-outline-warning btn-lg rounded mx-auto d-block projectbtn" type="button" onClick={() => this.setState({ open: true })}>FullStack</button>
  <AutoRotatingCarousel
    label='Close'
    open={this.state.open}
    onClose={() => this.setState({ open: false })}
    onStart={() => this.setState({ open: false })}
    style={{ position: 'absolute' }}
  >
    <Slider
      media={<img src='https://www.derekwebdev.com/gifs/whitaker.gif' alt="whitakeryates"/>}
      mediaBackgroundStyle={{ backgroundColor: "" }}
      style={{ backgroundColor: "#000"  }}
      title='Whitaker & Yates Furrier and Luxury Goods'
      subtitle='Storage Service MERN/Redux MongoDB w/User Authentication'
      linkVariable1={'https://whitakerandyates.herokuapp.com/'}
      linkVariable2={'https://github.com/derekwebdevcom/whitakerandyates'}
    />
    <Slider
      media={<img src='https://www.derekwebdev.com/gifs/banking.gif' alt="banking" />}
      mediaBackgroundStyle={{ backgroundColor: "" }}
      style={{ backgroundColor: "#000" }}
      title='Node.js Login System w/ Dashboard'
      subtitle='Web Token Passport Authentication'
      linkVariable1={'https://passportaccesstoken.herokuapp.com/'}
      linkVariable2={'https://github.com/derekwebdevcom/passportwebtokenlogin'}
    />
    <Slider
      media={<img src='https://derekwebdev.com/gifs/foreverbook.gif' alt="foreverbook"/>}
      mediaBackgroundStyle={{ backgroundColor: "" }}
      style={{ backgroundColor: "#000"  }}
      title='ForeverBook, permanent social media'
      subtitle='on the Etherium BlockChain. Can never be altered or changed.'
      linkVariable1={'https://foreverbook.derekwebdev.com/#/'}
      linkVariable2={'https://github.com/derekwebdevcom/ForeverBook'}
    />
  </AutoRotatingCarousel>

  <button className="btn btn-outline-warning btn-lg rounded mx-auto d-block projectbtn2" type="button" onClick={() => this.setState({ show: true })}>React UIs</button>

  <AutoRotatingCarousel
    label='Close'
    open={this.state.render}
    onClose={() => this.setState({ render: false })}
    onStart={() => this.setState({ render: false })}
    style={{ position: 'absolute' }}
  >
    <Slider
      media={<img src='https://www.derekwebdev.com/images/leap.png' alt="cms"/>}
      mediaBackgroundStyle={{ backgroundColor: "" }}
      style={{ backgroundColor: "#000"  }}
      title='Custom WordPress Theme'
      subtitle='Integrated WP Bakery special needs children NPO'
      linkVariable1={'https://www.leap.derekwebdev.com/'}
      linkVariable2={'https://github.com/derekwebdevcom/leap'}
    />
    <Slider
      media={<img src='https://derekwebdev.com/images/weather.PNG' alt="weather" />}
      mediaBackgroundStyle={{ backgroundColor: "" }}
      style={{ backgroundColor: "#000" }}
      title='What&apos;s the weather like in your city?'
      subtitle='Geolocation API based weather Vanilla JS'
      linkVariable1={'https://weather.derekwebdev.com/'}
      linkVariable2={'https://github.com/derekwebdevcom/weather'}
    />
    <Slider
      media={<img src='https://derekwebdev.com/images/craftbeer.png' alt="craftbar"/>}
      mediaBackgroundStyle={{ backgroundColor: "" }}
      style={{ backgroundColor: "#000"  }}
      title='Enjoy Craft Beer at this Speakeasy'
      subtitle='HTML5/Bootstrap/Responsive Static Page'
      linkVariable1={'https://craftbeer.derekwebdev.com/'}
      linkVariable2={'https://github.com/derekwebdevcom/craftbeer'}
    />
  </AutoRotatingCarousel>



  <button className="btn btn-outline-warning btn-lg rounded mx-auto d-block projectbtn2" type="button" onClick={() => this.setState({ render: true })}>WP/Misc</button>





  <AutoRotatingCarousel
    label='Close'
    open={this.state.show}
    onClose={() => this.setState({ show: false })}
    onStart={() => this.setState({ show: false })}
    style={{ position: 'absolute' }}
  >
    <Slider
      media={<img src='https://derekwebdev.com/gifs/hotel.gif' alt="hotel"/>}
      mediaBackgroundStyle={{ backgroundColor: "#000" }}
      style={{ backgroundColor: "#000"  }}
      title='Hotel'
      subtitle='This is a hotel booking UI built with React'
      linkVariable1={'https://hotel.derekwebdev.com/'}
      linkVariable2={'https://github.com/derekwebdevcom/hotelbooking'}
    />
    <Slider
      media={<img src='https://derekwebdev.com/gifs/phone.gif' alt="phone-store" />}
      mediaBackgroundStyle={{ backgroundColor: "#000" }}
      style={{ backgroundColor: "#000" }}
      title='Mobile Phone Store'
      subtitle='Shop for phones with PayPal checkout'
      linkVariable1={'https://reactphonestore.derekwebdev.com/'}
      linkVariable2={'https://github.com/derekwebdevcom/phonestore'}
    />
    <Slider
      media={<img src='https://derekwebdev.com/gifs/netflix.gif' alt="netflix"/>}
      mediaBackgroundStyle={{ backgroundColor: "#000" }}
      style={{ backgroundColor: "#000"  }}
      title='Netflix Clone'
      subtitle='Sign up for Netflix'
      linkVariable1={'https://demo4836.herokuapp.com/'}
      linkVariable2={'https://github.com/derekwebdevcom/Netflix'}
    />
  </AutoRotatingCarousel>
  </div>
  </Slide>

       </div>



        )
    }
}
