import React, { Component } from 'react'
import Navbar from '../Navbar/Navbar';
//import SideDrawer from '../SideDrawer/SideDrawer';

export default class Blog extends Component {
    render() {
        return (
           

<div className="page-bg">
<Navbar/>
<div className="animation-wrapper">
  <div className="particle particle-1"></div>
  <div className="particle particle-2"></div>
  <div className="particle particle-3"></div>
  <div className="particle particle-4"></div>
                
            </div>

            </div>
        )
    }
}
