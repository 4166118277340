import React from 'react';
import './SideDrawer.scss';
import { Link } from "react-router-dom";
const sideDrawer = props =>(
<nav className="side-drawer">
<Link to="/"><img src="https://derekwebdev.com/images/derekwebdevlogon.png" alt="logo" height="70vh" width="55vh" className="rounded mx-auto d-block pt-3"/></Link>
                      <ul><li><Link to="/home">Home</Link></li>
                    <li><Link to="/projects">Projects</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><a href="https://www.blog.derekwebdev.com" rel="noopener noreferrer" alt="blog">Blog</a></li>
    </ul>
</nav>
);
export default sideDrawer;