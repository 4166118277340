import React from 'react';
import { Link } from "react-router-dom";
import '../SideDrawer/DrawerToggleButton';
import './Navbar.css';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';


const navbar = props =>(
    <header className="navbar">
        <nav className="navbar_navigation">
            <div>
                <DrawerToggleButton click={props.drawerClickHandler}  />
            </div>
            <div className="navbar_logo"><Link to="/">DerekWebDev</Link> </div>
            <div className="spacer"></div>
            <div className="navbar_navigation-items">
                <ul>
                    <li><Link to="/home">Home</Link></li>
                    <li><Link to="/projects">Projects</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><a href="https://www.blog.derekwebdev.com" rel="noopener noreferrer" alt="blog">Blog</a></li>
                </ul>
            </div>
        </nav>
    </header>
);
export default navbar;