import React, { Component } from 'react'
import '../Main/Main.scss';
import Slide from 'react-reveal/Slide';
import Navbar from '../Navbar/Navbar';
import SideDrawer from '../SideDrawer/SideDrawer';
import Backdrop from '../Backdrop/Backdrop';
export default class Services extends Component {

  state={
    sideDrawerOpen: false
    
  };
  
  drawerToggleClickHandler = () => {
  this.setState((prevState) => {
    return {sideDrawerOpen: !prevState.sideDrawerOpen};
  });
  
  }; 
  
  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false}) 
  };
  
      render() {
        let sideDrawer;
        let backdrop;
  
        if (this.state.sideDrawerOpen){
          sideDrawer = <SideDrawer />
          backdrop = <Backdrop click={this.backdropClickHandler} />
        }
          return (
            
  <div className="page-bg">
  <Navbar drawerClickHandler={this.drawerToggleClickHandler}/>
  {sideDrawer}
  {backdrop}
 
<div style={{marginTop: '45px', marginBottom: '30px'}}>
        <Slide top>
        <h2 className="text-center mt-2 text-white">Software</h2>
          <h2 className="text-center mt-2 text-white">Engineering Services</h2>
        </Slide>
      </div>
<div className="container">
      <div className="col-12">
        <Slide left>
          <h4 className="float-right">HTML/CSS
          <br/>Bootstrap
          <br/>PHP MySQL
          <br/>ReactJS
          </h4>
          
        </Slide>
      </div>

      <div className="col-12">
        <Slide right>
          <h4 className="float-left">RestAPI
          <br/>UI/UX
          <br/>CMS
          <br/>MERN 
          </h4>
         
        </Slide>
      </div>
     
      </div>
     

<div className="animation-wrapper">
  <div className="particle particle-1"></div>
  <div className="particle particle-2"></div>
  <div className="particle particle-3"></div>
  <div className="particle particle-4"></div>
                
           

      </div>
      <div>
        <Slide bottom>
     <div className="rocket">
    <div className="rocket-body">
      <div className="body"></div>
      <div className="fin fin-left"></div>
      <div className="fin fin-right"></div>
      <div className="window"></div>
    </div>
    <div className="exhaust-flame"></div>
    <ul className="exhaust-fumes">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    <ul className="star">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
  </Slide>
      </div>
            </div>
        )
    }
}
